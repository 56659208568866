import React from "react";
import { Status, Layout, ViewTable, Dialog } from "@/components";
import { LibAuthCode } from "lib/Auth";
import WebApi from "@/web/api";
import UpdateUnitDialog from "./UpdateUnitDialog";
import { LibIProductUnit } from "lib/interface/ProductInterface";
import { IViewTableActionButton, IViewTableColumn } from "@/components/table/ViewTable";

interface IProductUnitProps {
  onlyShowEnableUnit: boolean;
  unitList: LibIProductUnit[];
  onRefresh(): void;
}

interface IState {
  updateUnit: {
    unitId: string;
    unitName: string;
    ratio: number;
    disabled: boolean;
  } | null;
  createUnitDialog: boolean;
}

export default class ProductUnit extends React.PureComponent<IProductUnitProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      updateUnit: null,
      createUnitDialog: false,
    };
  }

  private columns: IViewTableColumn<LibIProductUnit>[] = [
    {
      title: '状态',
      width: 80,
      align: 'center',
      render: (v, row) => {
        if (!row.disabled) return <Status.Using onClick={() => {
          this.clickDisableUnit(row);
        }}>启用</Status.Using>
        return <Status.Stop onClick={() => {
          this.clickEnableUnit(row);
        }}>停用</Status.Stop>
      }
    },
    {
      title: '单位类别',
      width: 100,
      align: 'center',
      render: (v, row, index) => {
        return row.ratio == 1 ? '主计量单位' : '辅助计量单位'
      }
    },
    {
      title: '单位名称',
      width: 100,
      dataIndex: 'unitName',
      align: 'center',
    },
    {
      title: '换算比例',
      titleAlign: 'left',
      align: 'left',
      dataIndex: 'ratio',
      width: 'auto',
      render: (v, row) => {
        if (v == 1) return v;
        const mainUnit = this.props.unitList.find(u => u.ratio === 1);
        return <div>
          {v}
          <span style={{marginLeft: 10}}>(1 {row.unitName} = {v} {mainUnit?.unitName})</span>
        </div>
      }
    }
  ]

  render() {

    return <div>
      <Layout.Title icon="icon-jiliang-mian" style={{marginTop: 30}}>计量单位</Layout.Title>
      <div>
        <ViewTable
          dataSource={this.props.onlyShowEnableUnit ? this.props.unitList.filter(item => !item.disabled) : this.props.unitList}
          pagination={false}
          columns={this.columns}
          actionButtons={this.actionButtons}
        />
      </div>

    {
      this.state.updateUnit &&
      <UpdateUnitDialog
        visible={!!this.state.updateUnit}
        unit={this.state.updateUnit}
        onCancel={this.onCancelUpdateUnitDialog}
        onOk={this.onOKUpdateUnitDialog}
      />
    }
    </div>
  }

  // 点击启用单位
  private clickEnableUnit = row => {
    Dialog.confirm({
      content: `确认启用 “${row.unitName}” 吗？`,
      onOk: () => {
        WebApi.product_unit_disable({
          unitId: row.unitId,
          disabled: false,
        }).then(() => {
          showSuccess.save();
          this.props.onRefresh();
        })
      }
    });
  }

  // 点击禁用单位
  private clickDisableUnit = row => {
    Dialog.confirm({
      content: `确认停用 “${row.unitName}” 吗？`,
      onOk: () => {
        WebApi.product_unit_disable({
          unitId: row.unitId,
          disabled: true,
        }).then(() => {
          showSuccess.save();
          this.props.onRefresh();
        })
      }
    });
  }

  // 点击修改计量单位
  private clickUpdateUnit = (row) => {
    this.setState({
      updateUnit: row
    });
  }

  // 取消修改计量单位
  private onCancelUpdateUnitDialog = () => {
    this.setState({
      updateUnit: null
    });
  }

  // 修改计量单位完成
  private onOKUpdateUnitDialog = () => {
    this.setState({
      updateUnit: null
    });
    this.props.onRefresh();
  }

  // 删除计量单位
  private clickDeleteUnit = (row) => {
    Dialog.confirm({
      content: '确认删除吗，删除后不能恢复',
      onOk: () => {
        WebApi.product_unit_delete({
          unitId: row.unitId
        })
        .then(() => {
          showSuccess.delete();
          this.props.onRefresh();
        });
      }
    });
  }

  // 取消新增
  private onCancelCreateDialog = () => {
    this.setState({
      createUnitDialog: false,
    });
  }

  // 新增完成
  private onOkCreateDialog = () => {
    this.setState({
      createUnitDialog: false,
    });
    this.props.onRefresh();
  }

  private actionButtons: IViewTableActionButton<LibIProductUnit>[] = [
    {
      type: 'update',
      authCode: LibAuthCode.product.update,
      onClick: this.clickUpdateUnit
    },
    {
      type: 'delete',
      disabled: row => row.ratio === 1,
      authCode: LibAuthCode.product.update,
      onClick: this.clickDeleteUnit
    },
  ];
}