import WebApi from '@/web/api';
import BigNumber from 'bignumber.js';
import { LibIProductImageItem, LibIProductSku, LibIProductUnit } from 'lib/interface/ProductInterface';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

export default class ProductDetailMobx {
  categoryId = ''
  categoryName = ''
  id = ''
  minBuyCount: number = 0;
  originalPrice = ''
  price:string = ''
  productDesc = ''
  productId = ''
  productName = ''
  sortValue?: number
  status = ''
  statusDesc = ''
  barcode = ''
  isUsedBySale: boolean = true;
  isUsedByPurchase: boolean = true;

  @observable
  coupons: any[] = []

  @observable
  activity?: {
    activityId?: string;
    activityName?: string;
    activityRule?: string;
    activityRuleDesc?: string;
    activityType?: string;
    activityTypeDesc?: string;
    endTime?: string;
    id?: string;
    limitCount?: any;
    productId?: string;
    startTime?: string;
  }

  @observable
  skuList: (LibIProductSku & {isUsedBySale: boolean; isUsedByPurchase: boolean})[] = []

  @observable
  unitList: (LibIProductUnit & {isUsedBySale: boolean; isUsedByPurchase: boolean})[] = []

  @observable
  searchWordList: string[] = []

  @observable
  imageLogo: LibIProductImageItem | undefined

  @observable
  imageSlideList: LibIProductImageItem[] = []

  @observable
  imageDetailList: LibIProductImageItem[] = []

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public findDetail(productId: string, callback?: Function) {
    WebApi.product_detail({
      productId
    })
    .then((data: any) => {
      runInAction(() => {
        for (let key in data) {
          this[key] = data[key];
        }
        callback && callback();
      })
    })
  }

  private getPrice = (priceKey: string) => {
    if (this.skuList.length === 0) return '';
    const prices: number[] = Array.from(new Set(this.skuList.map(s => Number(s[priceKey]))));
    if (prices.length === 1) return new BigNumber(prices[0]).toFixed(2);
    const min = Math.min.apply(null, prices);
    const max = Math.max.apply(null, prices);
    return new BigNumber(min).toFixed(2) + ' ~ ' + new BigNumber(max).toFixed(2)
  }
}