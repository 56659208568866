import React from "react";
import { Checkbox, Spin } from 'antd';
import ProductDetailMobx from '../ProductDetailMobx';
import { observer } from "mobx-react";
import { Button, Layout, Dialog } from "@/components";
import { LibAuthCode } from "lib/Auth";
import LibEnum from "lib/enum";
import WebApi from "@/web/api";
import { routerMap } from "@/router/agent_router";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ProductBase from "./ProductBase";
import ProductUnit from "./ProductUnit";
import { toJS } from "mobx";
import ProductSku from "./ProductSku";
import CreateUnitDialog from "./CreateUnitDialog";
import CreateSkuDialog from "./CreateSkuDialog";
import UpdateImageDialog from "./UpdateImageDialog";
import UpdateProductDialog from "./UpdateProductDialog";
const style = require('./product_detail.less');

interface IState {
  onlyShowEnableSku: boolean;
  onlyShowEnableUnit: boolean;
  updateProductDialog: boolean;
  createSkuDialog: boolean;
  createUnitDialog: boolean;
  updateImageDialog: boolean;
}

@observer
export default class ProductDetailPage extends React.PureComponent<RouterPropsType, IState> {

  private productMobx = new ProductDetailMobx();

  constructor(props) {
    super(props);
    this.state = {
      onlyShowEnableSku: false,
      onlyShowEnableUnit: false,
      updateProductDialog: false,
      createSkuDialog: false,
      createUnitDialog: false,
      updateImageDialog: false,
    };
  }

  componentDidMount() {
    document.addEventListener(routerMap.product_detail.getPath(this.props.match?.params.id), this.onRefresh);
    this.findDetail();
  }

  componentWillUnmount() {
    document.removeEventListener(routerMap.product_detail.getPath(this.props.match?.params.id), this.onRefresh);
  }

  onRefresh = () => {
    this.findDetail();
  }

  findDetail = () => {
    this.productMobx.findDetail(this.props.match?.params.id);
  }

  render() {

    return <Layout.Page height loading={!this.productMobx.productName}>
      <Layout.Toolbar title="商品详情">
        {/* <Checkbox checked={this.state.onlyShowEnableUnit} onChange={this.changeShowUnit}>只显示启用的计量单位</Checkbox>
        <Checkbox checked={this.state.onlyShowEnableSku} onChange={this.changeShowSku}>只显示启用的规格</Checkbox> */}
        <Button type="primary"
          style={{marginRight: 10, marginLeft: 10}}
          authCode={LibAuthCode.product.update}
          onClick={this.clickUpdateProduct}>修改商品</Button>
        <Button.Create
          authCode={LibAuthCode.product.update}
          onClick={this.clickCreateUnitDialog}
        >新增计量单位</Button.Create>
        <Button.Create
          authCode={LibAuthCode.product.update}
          onClick={this.clickCreateSkuDialog}
        >新增规格</Button.Create>
        <Button type="default"
          icon={<i className="iconfont icon-shangchuan1"/>}
          authCode={LibAuthCode.product.update}
          onClick={this.clickUpdateImageDialog}
        >上传图片</Button>
        {
          this.productMobx.status === LibEnum.PRODUCT_STATUS.UP.value &&
          <Button authCode={LibAuthCode.product.update} type="default" icon={<i className="iconfont icon-xiajia"/>} onClick={this.clickDownProduct}>下架</Button>
        }
        {
          this.productMobx.status === LibEnum.PRODUCT_STATUS.DOWN.value &&
          <Button authCode={LibAuthCode.product.update} type="default" icon={<i className="iconfont icon-shangjia"/>} onClick={this.clickUpProduct}>上架</Button>
        }
        <Button.Delete authCode={LibAuthCode.product.delete} onClick={this.clickDeleteProduct}>删除</Button.Delete>
      </Layout.Toolbar>
      <Layout flex1 scrollY>
        <ProductBase productMobx={this.productMobx}/>
        {
          this.state.updateProductDialog &&
           <UpdateProductDialog
            visible={this.state.updateProductDialog}
            productId={this.props.match?.params.id}
            productName={this.productMobx.productName}
            productDesc={this.productMobx.productDesc}
            categoryId={this.productMobx.categoryId}
            barcode={this.productMobx.barcode}
            minBuyCount={this.productMobx.minBuyCount}
            sortValue={this.productMobx.sortValue}
            searchWordList={this.productMobx.searchWordList}
            onCancel={this.clickCancelUpdateProductDialog}
            onOk={this.clickOkUpdateProductDialog}/>
        }
        
        <ProductUnit unitList={toJS(this.productMobx.unitList)} onlyShowEnableUnit={this.state.onlyShowEnableUnit} onRefresh={this.findDetail}/>
        <CreateUnitDialog visible={this.state.createUnitDialog} productId={this.props.match?.params.id} onCancel={this.clickCancelCreateUnitDialog} onOk={this.clickOkCreateUnitDialog}/>

        <ProductSku  skuList={toJS(this.productMobx.skuList)} onlyShowEnableSku={this.state.onlyShowEnableSku} onRefresh={this.findDetail} activity={this.productMobx.activity}/>
        <CreateSkuDialog visible={this.state.createSkuDialog} productId={this.props.match?.params.id} onCancel={this.clickCancelCreateSkuDialog} onOk={this.clickOkCreateSkuDialog}/>
        
        <Layout.Title icon="icon-image" style={{marginTop: 50}}>轮播图</Layout.Title>
        {
          this.productMobx.imageSlideList.map(item => {
            return <div key={item.imageId} className="product-image-detail">
              <img src={item.imageUrl}/>
            </div>
          })
        }
        {
          this.productMobx.imageDetailList.length === 0 && <div style={{width: 375, height: 60, lineHeight: '60px', textAlign: 'center'}}>暂无相关图片</div>
        }

        <Layout.Title icon="icon-image" style={{marginTop: 50}}>详情图</Layout.Title>
        {
          this.productMobx.imageDetailList.map(item => {
            return <div key={item.imageId} className="product-image-detail">
              <img src={item.imageUrl}/>
            </div>
          })
        }
        {
          this.productMobx.imageDetailList.length === 0 && <div style={{width: 375, height: 60, lineHeight: '60px', textAlign: 'center'}}>暂无相关图片</div>
        }

        <UpdateImageDialog
          visible={this.state.updateImageDialog}
          productId={this.props.match?.params.id}
          imageLogo={this.productMobx.imageLogo}
          imageSlideList={this.productMobx.imageSlideList}
          imageDetailList={this.productMobx.imageDetailList}
          onCancel={this.clickCancelUpdateImageDialog}
          onOk={this.clickOkUpdateImageDialog}
        />
      </Layout>
    </Layout.Page>
  }

  private changeShowSku = (e: CheckboxChangeEvent) => {
    this.setState({
      onlyShowEnableSku: e.target.checked
    });
  }

  private changeShowUnit = (e: CheckboxChangeEvent) => {
    this.setState({
      onlyShowEnableUnit: e.target.checked
    });
  }

  // 点击修改商品
  private clickUpdateProduct = () => {
    this.setState({
      updateProductDialog: true,
    });
  }

  // 取消修改商品
  private clickCancelUpdateProductDialog = () => {
    this.setState({
      updateProductDialog: false,
    });
  }

  // 修改商品后
  private clickOkUpdateProductDialog = () => {
    this.clickCancelUpdateProductDialog();
    this.findDetail();
  }

  // 点击新增计量单位
  private clickCreateUnitDialog = () => {
    this.setState({
      createUnitDialog: true,
    });
  }

  // 点击取消新增计量单位
  private clickCancelCreateUnitDialog = () => {
    this.setState({
      createUnitDialog: false,
    });
  }

  // 点击完成新增单位
  private clickOkCreateUnitDialog = () => {
    this.clickCancelCreateUnitDialog();
    this.findDetail();
  }

  // 点击新增规格
  private clickCreateSkuDialog = () => {
    this.setState({
      createSkuDialog: true,
    });
  }

  // 取消新增规格
  private clickCancelCreateSkuDialog = () => {
    this.setState({
      createSkuDialog: false,
    });
  }

  // 完成新增规格
  private clickOkCreateSkuDialog = () => {
    this.clickCancelCreateSkuDialog();
    this.findDetail();
  }

  // 下架
  private clickDownProduct = () => {
    WebApi.product_batch_update_status({
      productIdList: [this.productMobx.productId],
      status: LibEnum.PRODUCT_STATUS.DOWN.value,
    })
    .then(() => {
      showSuccess.save();
      this.productMobx.findDetail(this.productMobx.productId);
    })
  }

  // 上架
  private clickUpProduct = () => {
    WebApi.product_batch_update_status({
      productIdList: [this.productMobx.productId],
      status: LibEnum.PRODUCT_STATUS.UP.value,
    })
    .then(() => {
      showSuccess.save();
      this.productMobx.findDetail(this.productMobx.productId);
    })
  }

  // 删除商品
  private clickDeleteProduct = () => {
    Dialog.confirm({
      content: '确认删除吗，删除后不可恢复',
      onOk: () => {
        WebApi.product_delete({productId: this.props.match?.params.id}).then(() => {
          showSuccess.delete();
          this.props.history.close();
        })
      }
    })
  }

  // 点击上传图片
  private clickUpdateImageDialog = () => {
    this.setState({
      updateImageDialog: true,
    });
  }

  // 取消修改图片
  private clickCancelUpdateImageDialog = () => {
    this.setState({
      updateImageDialog: false,
    });
  }

  // 上传完成
  private clickOkUpdateImageDialog = () => {
    this.clickCancelUpdateImageDialog();
    this.findDetail();
  }
}