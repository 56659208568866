import React from "react";
import { Form, Input, InputNumber, Radio } from 'antd';
import { Dialog } from "@/components";
import { FormInstance } from "antd/lib/form";
import WebApi from "@/web/api";

interface IUpdateUnitDialogProps {
  visible: boolean;
  sku: {
    skuId: string;
    skuName: string;
    price: number;
  },
  onCancel(): void;
  onOk(): void;
}

export default class UpdateSkuDialog extends React.PureComponent<IUpdateUnitDialogProps> {
  private form = React.createRef<FormInstance>();

  state = {
    loading: false,
  };

  render() {
    return <Dialog visible={this.props.visible}
      full={false}
      okButtonProps={{
        loading: this.state.loading,
        disabled: this.state.loading
      }}
      width={460}
      title="修改规格"
      onOk={this.onOk}
      onCancel={this.props.onCancel}
    >
      <Form className="input-underline-dialog" ref={this.form} initialValues={{
        skuName: this.props.sku.skuName,
        price: this.props.sku.price,
      }}>
        <Form.Item label="规格名称" name="skuName" rules={[
          {
            required: true,
            message: '规格名称不能为空'
          },
        ]}>
          <Input/>
        </Form.Item>

        <Form.Item label="规格价格" name="price" rules={[
          {
            required: true,
            message: '价格不能为空'
          },
        ]}>
          <InputNumber precision={2} min={0}/>
        </Form.Item>

        <Form.Item label="启用状态" name="disabled" required>
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>停用</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Dialog>
  }

  onOk = () => {
    this.form.current?.validateFields().then(values => {
      this.setState({
        loading: true,
      });
      WebApi.product_sku_update({
        skuId: this.props.sku.skuId,
        skuName: values.skuName,
        price: values.price,
      })
      .then(() => {
        showSuccess.save();
        this.props.onOk();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      })
    })
  }
}