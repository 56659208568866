import { Button, Dialog } from "@/components";
import WebApi from "@/web/api";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, TreeSelect, Tag } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

interface IUpdateProductDialogProps {
  visible: boolean;
  productId: string;
  productName: string;
  categoryId: string;
  productDesc: string;
  barcode: string;
  minBuyCount: number;
  sortValue?: number;
  searchWordList: string[];
  onCancel(): void;
  onOk(): void;
}

interface IState {
  loading: boolean;
  categoryTree: any[];
  searchWordList: string[];
  showSearchWordInput: boolean;
}

export default class UpdateProductDialog extends React.PureComponent<IUpdateProductDialogProps, IState> {

  private form = React.createRef<FormInstance>()

  state = {
    loading: false,
    categoryTree: [],
    searchWordList: this.props.searchWordList || [],
    showSearchWordInput: false,
  }

  componentDidMount() {
    WebApi.category_list().then((data: any[]) => {
      this.setState({
        categoryTree: data.map(item => {
          return {
            title: item.categoryName,
            value: item.categoryId,
            disabled: true,
            children: item.children.map(child => {
              return {
                title: child.categoryName,
                value: child.categoryId,
              }
            }),
          };
        })
      });
    })
  }

  render() {
    return <Dialog title="修改商品"
      visible={this.props.visible}
      onCancel={this.props.onCancel}
      onOk={this.clickOk}
      width={700}
      full={false}
      okButtonProps={{
        disabled: this.state.loading,
        loading: this.state.loading,
      }}
      >
      <Form ref={this.form} className="input-underline-dialog update-product-dialog" initialValues={{
        productName: this.props.productName,
        productDesc: this.props.productDesc,
        sortValue: this.props.sortValue,
        minBuyCount: this.props.minBuyCount,
        barcode: this.props.barcode,
        searchWordList: this.props.searchWordList,
        categoryId: this.props.categoryId,
      }}>
        <div className="form-item-row">
          <Form.Item label="商品名称" name="productName" rules={[
            {
              required: true,
              message: '商品名称不能为空'
            }
          ]}>
            <Input/>
          </Form.Item>
        </div>
        <div className="form-item-row">
          <Form.Item label="商品分类" name="categoryId" rules={[
            {
              required: true,
              message: '商品分类不能为空'
            }
          ]}>
            <TreeSelect placeholder="选择分类" treeData={this.state.categoryTree}/>
          </Form.Item>
          <Form.Item label="起购量" name="minBuyCount" rules={[
            {
              required: true,
              message: '起购量不能为空'
            }
          ]}>
            <InputNumber precision={0} min={1}/>
          </Form.Item>
        </div>
        <div className="form-item-row">
          <Form.Item label="条形码" name="barcode">
            <Input/>
          </Form.Item>
          <Form.Item label="排序值" name="sortValue" rules={[
            {
              required: true,
              message: '排序值不能为空'
            }
          ]}>
            <InputNumber precision={0} min={0} placeholder="0~99999之间的数字，值越大排名越靠前"/>
          </Form.Item>
        </div>
        <div className="form-item-row">
          <Form.Item label="搜索关键词">
            {
              this.state.searchWordList?.map((item, index) => {
                return <Tag key={item + index} color="#daeef7" closable onClose={() => {
                  const next = [...this.state.searchWordList];
                  next.splice(index, 1);
                  this.setState({
                    searchWordList: next,
                  });
                }}>{item}</Tag>
              })
            }
            {
              this.state.showSearchWordInput &&
              <Input autoFocus placeholder="填写关键词" className="search-word-input" onBlur={this.onSearchWordInputBlur}/>
            }
            {
              !this.state.showSearchWordInput &&
              <Button type="primary" size="small" icon={<PlusOutlined/>} ghost onClick={this.clickAddSearchWord}>添加</Button>
            }
          </Form.Item>
        </div>
        <div className="form-item-row">
          <Form.Item label="商品描述" name="productDesc" rules={[
            {
              required: true,
              message: '商品描述不能为空'
            }
          ]}>
            <Input.TextArea rows={3} className="textarea"/>
          </Form.Item>
        </div>
      </Form>
    </Dialog>
  }

  private clickAddSearchWord = () => {
    this.setState({
      showSearchWordInput: true,
    });
  }

  private onSearchWordInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      showSearchWordInput: false,
    });
    if (e?.target.value) {
      this.setState({
        searchWordList: Array.from(new Set([...this.state.searchWordList, e.target.value]))
      });
    }
  }

  private clickOk = () => {
    if (this.state.loading) return;
    this.form.current?.validateFields()?.then((values) => {
      this.setState({
        loading: true,
      });
      WebApi
      .product_update({
        productId: this.props.productId,
        productName: values.productName,
        categoryId: values.categoryId,
        barcode: values.barcode,
        sortValue: values.sortValue,
        minBuyCount: values.minBuyCount,
        productDesc: values.productDesc,
        searchWordList: this.state.searchWordList,
      })
      .then(() => {
        this.setState({
          loading: false,
        });
        showSuccess.save();
        this.props.onOk();
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      })
    })
  }
}