import { Dialog, UploadImage } from "@/components";
import WebApi from "@/web/api";
import { LibIProductImageItem } from "lib/interface/ProductInterface";
import React from "react";

interface IUpdateImageDialogProps {
  visible: boolean;
  productId: string;
  imageLogo?: LibIProductImageItem;
  imageSlideList: LibIProductImageItem[];
  imageDetailList: LibIProductImageItem[];
  onCancel(): void;
  onOk(): void;
}

interface IState {
  loading: boolean;
}

export default class UpdateImageDialog extends React.PureComponent<IUpdateImageDialogProps, IState> {
  private sortedSlideImageList = [];
  private sortedDetailImageList = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return <Dialog title="上传图片"
      visible={this.props.visible}
      okText="确定"
      onCancel={this.props.onCancel}
      onOk={this.clickOk}
      okButtonProps={{
        loading: this.state.loading,
        disabled: this.state.loading,
      }}
    >
      <div>
        <section style={{marginBottom: 24}}>
          <p><strong>主图</strong>：至少需要1张，建议尺寸 375 x 375)</p>
          <UploadImage maxLength={1} extendData={{type: 'logo', objectId: this.props.productId}} fileList={this.props.imageLogo ? [this.props.imageLogo] : []}/>
        </section>

        <section style={{marginBottom: 24}}>
          <p><strong>轮播图</strong>：最多10张，建议尺寸 750 x 750)</p>
          <UploadImage sortable extendData={{type: 'slider', objectId: this.props.productId}} fileList={this.props.imageSlideList} onSortChange={this.slideSortChange}/>
        </section>

        <section>
          <p><strong>详情图</strong>：最多10张，建议尺寸 750 x 750)</p>
          <UploadImage sortable extendData={{type: 'detail', objectId: this.props.productId}} fileList={this.props.imageDetailList} onSortChange={this.detailSortChange}/>
        </section>
      </div>
    </Dialog>
  }

  private slideSortChange = (fileList) => {
    this.sortedSlideImageList = fileList;
  }

  private detailSortChange = (fileList) => {
    this.sortedDetailImageList = fileList;
  }

  private clickOk = () => {
    const promiseArray: Promise<any>[] = [];
    if (this.sortedSlideImageList.length > 0) {
      let slidePromise = WebApi.image_update({
        data: this.sortedSlideImageList.map((item: any, index) => {
          return {
            imageId: item.imageId,
            sortValue: index,
          };
        })
      });
      promiseArray.push(slidePromise);
    }

    if (this.sortedDetailImageList.length > 0) {
      let detailPromise = WebApi.image_update({
        data: this.sortedDetailImageList.map((item: any, index) => {
          return {
            imageId: item.imageId,
            sortValue: index,
          };
        })
      });
      promiseArray.push(detailPromise);
    }

    if (promiseArray.length > 0) {
      this.setState({
        loading: true,
      });
      Promise.all(promiseArray).then(() => {
        this.setState({
          loading: false,
        });
        this.props.onOk();
      }).catch(() => {
        this.setState({
          loading: false,
        });
        this.props.onOk();
      })
    } else {
      this.props.onOk();
    }
  }
}