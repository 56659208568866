import { Dialog } from "@/components";
import WebApi from "@/web/api";
import { Form, Input, InputNumber } from 'antd';
import { FormInstance } from "antd/lib/form";
import React from "react";

interface IState {
  loading: boolean;
}

interface ICreateUnitDialogProps {
  visible: boolean;
  productId: string;
  onCancel(): void;
  onOk(): void;
}

export default class CreateUnitDialog extends React.PureComponent<ICreateUnitDialogProps, IState> {

  private form = React.createRef<FormInstance>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return <Dialog title="新增辅计量单位"
      visible={this.props.visible}
      okButtonProps={{
        loading: this.state.loading,
        disabled: this.state.loading,
      }}
      width={460}
      full={false}
      onCancel={this.props.onCancel}
      onOk={this.clickOk}
    >
      <Form ref={this.form} className="input-underline-dialog">
        <Form.Item label="单位名称" name="unitName" rules={[
          {
            required: true,
            message: '单位名称不能为空'
          }
        ]}>
          <Input/>
        </Form.Item>
        <Form.Item label="换算比例" name="ratio" rules={[
          {
            required: true,
            message: '换算比例不能为空'
          }
        ]}>
          <InputNumber precision={0} min={2}/>
        </Form.Item>
      </Form>
    </Dialog>
  }

  private clickOk = () => {
    this.form.current?.validateFields().then((values) => {
      this.setState({
        loading: true,
      });

      WebApi.product_unit_create({
        productId: this.props.productId,
        unitName: values.unitName,
        ratio: values.ratio,
      }).then(() => {
        showSuccess.save();
        this.props.onOk();
      }).catch(e => {
        this.setState({
          loading: false,
        });
      })
    });
  }
}