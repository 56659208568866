import React from "react";
import { observer } from "mobx-react";
import { Status, Layout, ViewTable, Dialog } from "@/components";
import { LibAuthCode } from "lib/Auth";
import WebApi from "@/web/api";
import UpdateSkuDialog from "./UpdateSkuDialog";
import { LibIProductSku } from "lib/interface/ProductInterface";
import { IViewTableColumn } from "@/components/table/ViewTable";
const style = require('./product_detail.less');

interface IProductSkuProps {
  activity: any;
  onlyShowEnableSku: boolean;
  skuList: LibIProductSku[];
  onRefresh(): void;
}

interface IState {
  updateSku: any;
  createSkuDialog: boolean;
}

@observer
export default class ProductSku extends React.PureComponent<IProductSkuProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      updateSku: null,
      createSkuDialog: false,
    };
  }

  columns: IViewTableColumn<LibIProductSku>[] = [
    {
      title: '状态',
      width: 80,
      align: 'center',
      render: (v, row) => {
        if (!row.disabled) return <Status.Using onClick={() => {
          this.clickDisableSku(row);
        }}>启用</Status.Using>
        return <Status.Stop onClick={() => {
          this.clickEnableSku(row);
        }}>停用</Status.Stop>
      }
    },
    {
      title: '价格',
      dataIndex: 'price',
      align: 'right',
      width: 100,
    },
    {
      title: '活动价格',
      dataIndex: 'price',
      align: 'right',
      width: 100,
      render: (v, row) => {
        if (this.props.activity) {
          return row.price;
        }
        return '无';
      }
    },
    {
      title: '规格',
      dataIndex: 'skuName',
      titleAlign: 'left',
      width: 'auto',
    },
  ]

  render() {

    return <div>
      <Layout.Title icon="icon-guigexinghao" style={{marginTop: 30}}>规格</Layout.Title>
      <div>
        <ViewTable
          pagination={false}
          columns={this.columns}
          rowKey={row => row['skuId']}
          dataSource={this.props.onlyShowEnableSku ? this.props.skuList.filter(item => !item.disabled) : this.props.skuList}
          actionButtons={[
            {
              type: 'update',
              authCode: LibAuthCode.product.update,
              onClick: this.clickUpdateSku
            },
            {
              type: 'delete',
              authCode: LibAuthCode.product.update,
              onClick: this.clickDeleteSku
            },
          ]}
        />
      </div>
        
      {
        this.state.updateSku &&
        <UpdateSkuDialog
          visible={!!this.state.updateSku}
          sku={this.state.updateSku}
          onCancel={this.onCancelUpdateSkuDialog}
          onOk={this.onOkUpdateSkuDialog}
        />
      }
    </div>
  }

  // 点击启用规格
  clickEnableSku = row => {
    Dialog.confirm({
      content: `确认启用 “${row.skuName}” 吗？`,
      onOk: () => {
        WebApi.product_sku_disable({
          skuId: row.skuId,
          disabled: false,
        }).then(() => {
          showSuccess.save();
          this.props.onRefresh();
        })
      }
    });
  }

  // 点击禁用规格
  clickDisableSku = row => {
    Dialog.confirm({
      content: `确认停用 “${row.skuName}” 吗？`,
      onOk: () => {
        WebApi.product_sku_disable({
          skuId: row.skuId,
          disabled: true,
        }).then(() => {
          showSuccess.save();
          this.props.onRefresh();
        })
      }
    });
  }

  // 点击修改规格
  clickUpdateSku = (row) => {
    this.setState({
      updateSku: row
    });
  }

  // 取消修改规格
  onCancelUpdateSkuDialog = () => {
    this.setState({
      updateSku: null
    });
  }

  // 修改规格完成
  onOkUpdateSkuDialog = () => {
    this.setState({
      updateSku: null
    });
    this.props.onRefresh();
  }

  // 删除规格
  clickDeleteSku = row => {
    if (this.props.skuList.filter(item => !item.disabled).length === 1) {
      showError('商品最少需要一个启用的规格，不能删除');
      return;
    }
    Dialog.confirm({
      content: '确认删除吗，删除后不能恢复',
      onOk: () => {
        WebApi.product_sku_delete({
          skuId: row.skuId
        })
        .then(() => {
          showSuccess.delete();
          this.props.onRefresh();
        });
      }
    });
  }

  // 取消新增
  private onCancelCreateSkuDialog = () => {
    this.setState({
      createSkuDialog: false,
    });
  }

  // 新增完成
  private onOkCreateSkuDialog = () => {
    this.setState({
      createSkuDialog: false,
    });
    this.props.onRefresh();
  }
}