import React from "react";
import { Form, Input, InputNumber, Radio } from 'antd';
import { Dialog } from "@/components";
import { FormInstance } from "antd/lib/form";
import WebApi from "@/web/api";

interface IUpdateUnitDialogProps {
  visible: boolean;
  unit: {
    unitId: string;
    unitName: string;
    ratio: number;
    disabled: boolean;
  },
  onCancel(): void;
  onOk(): void;
}

export default class UpdateUnitDialog extends React.PureComponent<IUpdateUnitDialogProps> {
  private form = React.createRef<FormInstance>();

  state = {
    loading: false,
  };

  render() {
    return <Dialog visible={this.props.visible}
      full={false}
      okButtonProps={{
        loading: this.state.loading,
        disabled: this.state.loading
      }}
      title="修改计量单位"
      width={460}
      onOk={this.onOk}
      onCancel={this.props.onCancel}
    >
      <Form className="input-underline-dialog" ref={this.form} initialValues={{
        unitName: this.props.unit.unitName,
        ratio: this.props.unit.ratio,
        disabled: this.props.unit.disabled
      }}>
        <Form.Item label="单位类别" required>
          <span>{this.props.unit.ratio == 1 ? '主计量单位' : '辅计量单位'}</span>
        </Form.Item>
        <Form.Item label="单位名称" name="unitName" rules={[
          {
            required: true,
            message: '单位名称不能为空'
          },
        ]}>
          <Input/>
        </Form.Item>

        {
          this.props.unit.ratio !== 1 &&
          <Form.Item label="换算比例" name="ratio" rules={[
            {
              required: true,
              message: '换算比例不能为空'
            },
          ]}>
            <InputNumber precision={0} min={2}/>
          </Form.Item>
        }

        {
          this.props.unit.ratio == 1 &&
          <Form.Item label="换算比例" name="ratio" required>
            <span>主计量单位换算比例为1，且不可修改</span>
          </Form.Item>
        }

        {/* <Form.Item label="启用状态" name="disabled" required>
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>停用</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </Dialog>
  }

  onOk = () => {
    this.form.current?.validateFields().then(values => {
      this.setState({
        loading: true,
      });
      WebApi.product_unit_update({
        unitId: this.props.unit.unitId,
        unitName: values.unitName,
        ratio: values.ratio,
      })
      .then(() => {
        showSuccess.save();
        this.props.onOk();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      })
    })
  }
}