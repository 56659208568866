import React from "react";
import { Tag } from 'antd';
import ProductDetailMobx from '../ProductDetailMobx';
import { observer } from "mobx-react";
import {  Layout } from "@/components";
import classNames from "classnames";
import LibEnum from "lib/enum";

interface IProductBaseProps {
  productMobx: ProductDetailMobx;
}

@observer
export default class ProductBase extends React.PureComponent<IProductBaseProps> {

  private productMobx = this.props.productMobx

  render() {

    return <Layout flex1 scrollY>
      <Layout.Title>基本信息</Layout.Title>
      <div className="product-base-info">
        <section className="left">
          <div className="image">
            <img src={this.productMobx.imageLogo?.imageUrl}/>
          </div>
        </section>
        <section className="right">
          <h4>
            {
              this.productMobx.status === LibEnum.PRODUCT_STATUS.DOWN.value &&
              <Tag color="gray" style={{padding: '0 5px'}}>已下架</Tag>
            }
            {
              this.productMobx.status === LibEnum.PRODUCT_STATUS.UP.value &&
              <Tag color="#87d068" style={{padding: '0 5px'}}>在售</Tag>
            }
            {this.productMobx.productName}
          </h4>
          <div className="price">
            {
              this.productMobx.originalPrice &&
              <PriceItem label="价格">
                <div style={{color: '#333'}}>
                  ¥
                  <span style={{fontSize: 14, marginLeft: 3, textDecoration: 'line-through'}}>{this.productMobx.price}</span>
                </div>
              </PriceItem>
            }

            <PriceItem label={this.productMobx.activity ? '活动价' : '价格'}>
              <div style={{lineHeight: '24px', color: '#FF0036'}}>
                <span style={{fontSize: 18, verticalAlign: 'middle', marginBottom: -6, display: 'inline-block', marginRight: 3}}>¥</span>
                <span style={{fontSize: 30, verticalAlign: 'middle'}}>{this.productMobx.price}</span>
              </div>
            </PriceItem>

            {
              this.productMobx.coupons.length > 0 &&
              <PriceItem label="优惠券"></PriceItem>
            }

            {
              this.productMobx.activity &&
              <PriceItem label="活动">
                <div>
                  <p style={{color: '#FF0036', fontSize: 16}}>{this.productMobx.activity?.activityName}</p>
                  {
                    (this.productMobx.activity?.activityRuleDesc || '').split(';').map((item, index) => {
                      return <div key={index}>{item}</div>
                    })
                  }
                </div>  
              </PriceItem>
            }
          </div>

          <PropertyItem label="商品编号">{this.productMobx.productId}</PropertyItem>

          <PropertyItem label="商品分类">{this.productMobx.categoryName}</PropertyItem>

          <PropertyItem label="条形码">{this.productMobx.barcode}</PropertyItem>

          <PropertyItem label="排序值">{this.productMobx.sortValue}</PropertyItem>

          <PropertyItem label="起购量">{this.productMobx.minBuyCount}</PropertyItem>
          
          <PropertyItem label="搜索关键词">
            {
              this.productMobx.searchWordList.join('、')
            }
            {
              this.productMobx.searchWordList.length === 0 && '无'
            }
          </PropertyItem>

          <PropertyItem label="商品描述">{this.productMobx.productDesc}</PropertyItem>
        </section>
      </div>
    </Layout>
  }
}

const PriceItem = (props: {
  label: string;
  children?: React.ReactNode
}) => {
  return <div className="property">
  <div className="property-name" style={{paddingTop: 5, marginBottom: 10}}>{props.label}</div>
  <div className="property-value">
    {props.children}
  </div>
</div>
}

const PropertyItem = (props: {
  label: string;
  tag?: boolean;
  children?: React.ReactNode
}) => {
  return <div className="property-box">
  <div className="property">
    <div className={classNames('property-name', {
      tag: props.tag
    })}>{props.label}</div>
    <div className="property-value">{props.children}</div>
  </div>
</div>
}